export enum CredoType {
  SYMBOLE_DES_APOTRE = "Symbole des apôtres",
  SYMBOLE_DE_NICEE_CONSTANTINOPLE = "Symbole de Nicée Constantinople",
  APOTRE_AND_CONSTANTINOPLE = "Symbole des apôtres + Symbole de Nicée Constantinople",
}

export const credos = {
  [CredoType.SYMBOLE_DES_APOTRE]: `Je crois en Dieu, le Père tout-puissant,
Créateur du ciel et de la terre.
Et en Jésus Christ,
Son Fils unique, notre Seigneur ;
Qui a été conçu du Saint Esprit,
Est né de la Vierge Marie,
\\n
A souffert sous Ponce Pilate, a été crucifié,
Est mort et a été enseveli, est descendu aux enfers ;
Le troisième jour est ressuscité des morts,
Est monté aux cieux,
Est assis à la droite de Dieu le Père tout-puissant,
D’où il viendra juger les vivants et les morts.
\\n
Je crois en l’Esprit Saint,
à la sainte Église catholique,
à la communion des saints,
à la rémission des péchés,
à la résurrection de la chair, à la vie éternelle.
Amen`,
  [CredoType.SYMBOLE_DE_NICEE_CONSTANTINOPLE]: `Je crois en un seul Dieu,
Le Père tout puissant,
Créateur du ciel et de la terre,
De l’univers visible et invisible,
\\n
Je crois en un seul Seigneur, Jésus Christ,
Le Fils unique de Dieu,
Né du Père avant tous les siècles :
Il est Dieu, né de Dieu,
Lumière, née de la lumière,
Vrai Dieu, né du vrai Dieu
\\n
Engendré non pas créé,
Consubstantiel au Père ;
Et par lui tout a été fait.
Pour nous les hommes, et pour notre salut,
Il descendit du ciel;
Par l’Esprit Saint,
Il a pris chair de la Vierge Marie,
Et s’est fait homme.
\\n
Crucifié pour nous sous Ponce Pilate,
Il souffrit sa passion et fut mis au tombeau.
Il ressuscita le troisième jour,
Conformément aux Ecritures, et il monta au ciel;
Il est assis à la droite du Père.
\\n
Il reviendra dans la gloire,
Pour juger les vivants et les morts
Et son règne n’aura pas de fin.
\\n
Je crois en l’Esprit Saint,
Qui est Seigneur et qui donne la vie;
Il procède du Père et du Fils.
Avec le Père et le Fils,
Il reçoit même adoration et même gloire;
Il a parlé par les prophètes.
\\n
Je crois en l’Eglise,
Une, sainte, catholique et apostolique.
Je reconnais un seul baptême
Pour le pardon des péchés.
J’attends la résurrection des morts,
Et la vie du monde à venir.
Amen`
}