export function getRandomKey(): string {
  const s4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
  return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
}

export function slugify(str: string): string {
  return str.toString().toLowerCase().trim()
    .normalize('NFD')                 // separate accent from letter
    .replace(/[\u0300-\u036f]/g, '')  // remove all separated accents
    .replace(/\s+/g, '-')             // replace spaces with -
    .replace(/&/g, '-and-')           // replace & with 'and'
    .replace(/[^\w-]+/g, '')         // remove all non-word chars
    .replace(/--+/g, '-')           // replace multiple '-' with single '-'
}

export function encode(str: string): string {
  // make URL friendly:
  str = btoa(str)
  return str.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

export function decode(str: string): string {
  // reverse to original encoding
  if (str.length % 4 != 0) {
    str += ('===').slice(0, 4 - (str.length % 4));
  }
  str = str.replace(/-/g, '+').replace(/_/g, '/');
  return atob(str)
}

export function groupBy(arr: [], property: string): { [key: string]: any[]; } {
  return arr.reduce(function (memo, x) {
    if (!memo[x[property]]) {
      memo[x[property]] = [];
    }
    memo[x[property]].push(x);
    return memo;
  }, {});
}

export function getTimeDiff(timestamp1: number) {
  const today = new Date();
  const diff = getDifferencesBetweenDates(timestamp1, today);
  if (diff.months > 0) {
    return `${diff.months} mois`
  } else if (diff.days > 1) {
    return `${diff.days} jours`
  } else if (diff.days == 0) {
    return `aujourd'hui`
  }
  return null;
}

export function removeSubstrings(string: string, array: string[]): string {
  array.forEach((element: string) => {
    string = string.split(element)[0]
  });
  return string;
}

function getDifferencesBetweenDates(timestamp1: number, date2: any) {
  const date1: any = new Date(timestamp1);
  const timeDifference = Math.abs(date2 - date1);

  const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24)) - 1;
  const monthsDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24 * 30.44)) - 1;
  return { days: daysDifference, months: monthsDifference };
}

export function deepEqual(x: any, y: any, excludeKeys: string[] = []): boolean {
  const ok = Object.keys;
  const tx = typeof x;
  const ty = typeof y;
  
  // If either x or y is an object and they have the same type
  if (x && y && tx === 'object' && tx === ty) {
    const keysX = ok(x).filter(key => !excludeKeys.includes(key)); // Exclude specified keys from x
    const keysY = ok(y).filter(key => !excludeKeys.includes(key)); // Exclude specified keys from y
    
    // Check if the filtered keys length are equal
    if (keysX.length !== keysY.length) return false;
    
    // Check equality for each filtered key
    return keysX.every((key: any) => deepEqual(x[key], y[key], excludeKeys));
  }
  
  // Otherwise, compare directly
  return x === y;
}

export function removeParenthesesContent(sentence: string): string {
  return sentence.replace(/\s*\(.*?\)\s*/g, '');
}

export function removeBracketsContent(sentence: string): string {
  return sentence.replace(/\s*\[.*?\]\s*/g, '');
}

export function getRandomNumber(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function debounce(fn: any, wait: any) {
  let timer: any;
  return (...args: any[]) => {
    if (timer) {
      clearTimeout(timer); // clear any pre-existing timer
    }
    timer = setTimeout(() => {
      fn.apply(this, args); // call the function if time expires
    }, wait);
  }
}

export function isSameDay(date1: string|Date|null, date2: Date|null) {
  if (date1 == null || date2 == null) return false
  date1 = typeof date1 === 'string' ? new Date(date1) : date1
  return (date1.getDate() === date2.getDate() 
  && date1.getMonth() === date2.getMonth()
  && date1.getFullYear() === date2.getFullYear())
}

export function getDateLabel(date: Date) {
  const options: any = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }
  return date.toLocaleDateString('fr-FR', options)
}

// export function splitTextIntoChunks(text: string, maxChunkSize: number, firstMaxChunkSize: number): string[] {
//   const replace = `.{${maxChunkSize}}\\S*\\s+`;
//   const regex = new RegExp(replace,"g");
//   return text.replace(regex, "$&@").split(/\s+@/);
// }
export function splitTextIntoChunks(text: string, maxChunkSize: number, firstMaxChunkSize: number): string[] {
  const firstReplace = `.{${Math.round(firstMaxChunkSize)}}\\S*\\s+`;
  const firstRegex = new RegExp(firstReplace, "g");
  
  const remainingReplace = `.{${maxChunkSize}}\\S*\\s+`;
  const remainingRegex = new RegExp(remainingReplace, "g");
  
  const match = firstRegex.exec(text);
  if (!match) return [text];
  
  const firstChunk = match[0];
  const remainingText = text.slice(firstChunk.length);
  
  return [firstChunk.trim(), ...remainingText.replace(remainingRegex, "$&@").split(/\s+@/)];
}
